<template>
    <div class="dataB-dataCollection-con">
        <div class="main">
            <div class="tit">
                <span class="btn-post">POST</span>
                <span>/collection/upload/{{ entityId }} 上传数据接口地址</span>
            </div>
            <div class="parameters">
                Parameters
            </div>
            <div class="parameters-con">
                <div class="tit">
                    <span class="head-name">Name</span>
                    <span class="description">Description</span>
                </div>
                <div class="item">
                    <div class="item-left">
                        <span>entity_id</span>
                        <span class="required">*required</span>
                    </div>
                    <div class="item-right">
                        数据实例id
                    </div>
                </div>
                <div class="item">
                    <div class="item-left">
                        <span>input</span>
                    </div>
                    <div class="item-right">
                        <h4>Example Value</h4>
                        <div class="parameters-input">
                            <el-input type="textarea" v-model="parametersStr" readonly></el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            parametersStr: '',
        };
    },
    watch: {
        entityId(val) {
            this.entityId = val;
            this.parametersStr = '';
            if (!this.entityId) {
                // this.$message.warning('请先选择树节点');
                return;
            }
            this.getParameters();
        },
    },
    computed: {
    },
    methods: {
        getParameters() {
            this.$axios
                .get('/interfaceApi/datacenter/collection/example/' + this.entityId)
                .then(res => {
                    if (res) {
                        this.parametersStr = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        if (!this.entityId) {
            // this.$message.warning('请先选择树节点');
            return;
        }
        this.parametersStr = '';
        this.getParameters();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.dataB-dataCollection-con
    float left
    width calc(100% - 2.3rem)
    height 100%
    background #fff
    margin-left .1rem
    padding .2rem
    .main
        height 100%
        border 1px solid #49cc90
        border-radius 3px
        .tit
            height .4rem
            border-bottom 1px solid #49cc90
            .btn-post
                display inline-block
                width .8rem
                height .3rem
                text-align center
                line-height .3rem
                color #fff
                background #49cc90
                border-radius 3px
                margin .05rem .2rem
        .parameters
            height .5rem
            border-bottom 2px solid #d8e5df
            padding-left .2rem
            line-height .5rem
        .parameters-con
            padding .15rem
            background #e8f6f0
            overflow hidden
            .tit
                border-bottom 1px solid #c6d2d0
                line-height .38rem
                .head-name
                    width 4rem
                    display inline-block
                .description
                    display inline-block
                    width calc(100% - 4rem)
            .item
                line-height .38rem
                .item-left
                    width 4rem
                    float left
                    .required
                        color red
                .item-right
                    width calc(100% - 4rem)
                    float left
                    .parameters-input
                        background #41444e
                        border-radius 3px
                        padding .1rem
                        color #fff
                        textarea
                            min-height 3rem!important
                            background #41444e
                            color #fff
                            border none

</style>